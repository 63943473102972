/* global document */
import React from "react";
import ReactDOM from "react-dom";

import { AuthFormRoot } from "@muchbetteradventures/signup-form-lib";

import { getUrlParameter } from "./utils";

// If valid container element is found,
// renders an inline auth form that is always open and mounted on the page

const targetElement = document.getElementById("auth-form");
if (targetElement !== null) {
  const nextUrl = targetElement.href
  || targetElement.dataset.next
  || getUrlParameter("next")
  || "/";

  const {
    formType = "signin",
    preventSwitchFromSigninForm,
    preventSwitchFromSignupForm,
    signinFormBackButtonText,
    signinSubmitButtonText,
    signupFormTitle,
    signupFormPasswordHelperText,
    signupSubmitButtonText,
    skipConsents,
  } = targetElement.dataset;

  ReactDOM.render(
    <AuthFormRoot
      alwaysOpen
      formType={formType}
      nextUrl={nextUrl}
      preventSwitchFromSigninForm={preventSwitchFromSigninForm}
      preventSwitchFromSignupForm={preventSwitchFromSignupForm}
      signinFormBackButtonText={signinFormBackButtonText}
      signinSubmitButtonText={signinSubmitButtonText}
      signupSubmitButtonText={signupSubmitButtonText}
      signupFormTitle={signupFormTitle}
      signupFormPasswordHelperText={signupFormPasswordHelperText}
      skipConsents={skipConsents === "true"}
    />,
    targetElement,
  );
}
